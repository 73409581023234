import { Box, colors } from "@mui/material";
import { cardLookup } from "./helpers/cardLookup";
import { Cancel } from "@mui/icons-material";

const CardSelection = (props) => {
    const { image, onClick } = props

    return(
        <Box style={{
            flex: "0 0 7.5%",
            width: "100%",
            height: "100%",
            display: "flex",
        }}>
            <img src={cardLookup(image)} style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                objectPosition: "center",
                transform:  "rotate(90deg)"
            }} alt="select-card"/>
            <Cancel style={{
                color: colors.red[600],
                background: colors.grey[100],
                borderRadius: "50%",
                padding: 0,
                marginLeft: "-20px",
                marginTop: "-20px",
                fontSize: "20px",
                zIndex: "100",
                cursor: "pointer"
            }} onClick={() => onClick(image)}/>
        </Box>
    )
}

export default CardSelection;