import { createSlice } from "@reduxjs/toolkit";
import { HttpRequest } from "../context/ApiRequest";

const gameInitSlice = createSlice({
    name: "gameInit",
    initialState: {
        gameCreationStatus : null,
        playerJoinStatus : null,
        gameStartStatus : {}
    },
    reducers: {
        setGameCreationStatus : (state, action) => {
            state.gameCreationStatus = action.payload
        },
        setPlayerJoinStatus : (state, action) => {
            state.playerJoinStatus = action.payload
        },
        setGameStartStatus : (state, action) => {
            state.gameStartStatus = action.payload
        }
    } 
})

export const createGame = (gameDetails) => {
    return(dispatch) => {
        HttpRequest("POST", "game/create", gameDetails, (response) => {
            dispatch(setGameCreationStatus(response.data))
        })
    }
}

export const joinGame = (gameId, playerDetails) => {
    return(dispatch) => {
        HttpRequest("PUT", `game/join/${gameId}`, playerDetails, (response) => {
            dispatch(setPlayerJoinStatus(response.data))
        })
    }
}

export const startGame = (gameId) => {
    return(dispatch) => {
        HttpRequest("PUT", `game/start/${gameId}`, null, (response) => {
            dispatch(setGameStartStatus(response))
        })
    }
}

export const getGame = (gameId, completionHandler) => {
    return(dispatch) => {
        HttpRequest("GET", `game/get/${gameId}`, null, completionHandler)
    }
}

export const clearCheckedBy = (gameId, completionHandler) => {
    return(dispatch) => {
        HttpRequest("PUT", `game/clear-check/${gameId}`, null, completionHandler)
    }
}

export const { setGameCreationStatus, setPlayerJoinStatus, setGameStartStatus } = gameInitSlice.actions;
export const gameCreationSelector = (state) => state.gameInit.gameCreationStatus;
export const playerJoinSelector = (state) => state.gameInit.playerJoinStatus;
export const gameStartSelector = (state) => state.gameInit.gameStartStatus;
export default gameInitSlice.reducer;