import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getGame, startGame } from "./service/GameInitSlice";

const StartGame = (props) => {
    const { game } = props
    const [players, setPlayers] = useState([])
    const [disableBtn, setDisableBtn] = useState(true)

    const dispatch = useDispatch()

    useEffect(() => {
        var getPlayersInterval = setInterval(() => {
            dispatch(getGame(game, (response) => {
                if(response.players.length === response.playersCount){
                    clearInterval(getPlayersInterval)
                    setDisableBtn(false)
                }
                setPlayers(response.players)
            }))
        }, 1000)
        //eslint-disable-next-line
    },[])

    const handleStartGame = () => {
        dispatch(startGame(game))
    }

    return (
        <Box style={{
            padding: "2vw"
        }}>
            <Box style={{marginBottom: "1vw", textAlign: "center"}}>
                <Typography variant="h5" style={{textAlign: "center"}}>
                    You have successfully joined the game
                </Typography>
                <Typography variant="body2">
                    Copy link <i><b>{window.location.href}</b></i> and share with your friends to start the game
                </Typography>
            </Box>
            <Box>
                <Typography variant="h6">
                    Players List
                </Typography>
                <ol>
                    {players.map(player => 
                        <li key={player.id} style={{
                            marginBottom: "0.5vw",
                        }}>
                            <Typography variant="body1">{player.name}</Typography>
                        </li>
                    )}
                </ol>
            </Box>
            <Box style={{
                display: "flex",
                justifyContent: "flex-end", 
                marginTop: "1vw"           
            }}>
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleStartGame}
                    disabled={disableBtn}
                >
                    Start Game
                </Button>
            </Box>
        </Box>
    )
}

export default StartGame;