import { Badge, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { cardLookup } from "./helpers/cardLookup";

const GameCard = (props) => {
    const { image, gameCards, selectedCards, onClick } = props;
    const [count, setCount] = useState(0)

    useEffect(() => {
       gameCards?.forEach(card => {
           if (card.name === image) {
               setCount(card.count)
           }
       })
       //eslint-disable-next-line
    }, [gameCards])

    return (
        <Box className={gameCards?.filter(x => x.name === image).length === 0 ? "image" : ""} style={{
            flex: "0 0 7.5%",
            width: "100%",
            height: "100%",
            position: "relative",
            display: "inline-block"
        }}>
            <Badge badgeContent={gameCards?.filter(x => x.name === image).length === 0 ? 0 : count} color="primary">
                <img src={cardLookup(image)} style={{
                    display: "block",
                    maxWidth: "100%",
                    height: "100%",
                    transform:  "rotate(90deg)",
                    cursor: (gameCards?.filter(x => x.name === image).length === 0 || selectedCards.length >= 8) ? "not-allowed" : "pointer",
                }} onClick={() => onClick(image)} alt="deck-card"/>
            </Badge>
        </Box>
    )
}

export default GameCard;