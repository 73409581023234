import { Alert, Box, Button, Dialog, Fab, Grid, List, ListItem, MenuItem, Select, TextField, Typography, colors } from "@mui/material";
import GameCard from "./GameCard";
import { useEffect, useState } from "react";
import CardSelection from "./CardSelection";
import NewGame from "./NewGame";
import { useDispatch, useSelector } from "react-redux";
import { clearCheckedBy, gameCreationSelector, gameStartSelector, getGame, playerJoinSelector } from "./service/GameInitSlice";
import JoinGame from "./JoinGame";
import StartGame from "./StartGame";
import { checkCards, passRound, playCards } from "./service/PlayerActionSlice";
import { Message, Person2Rounded, TaskAlt } from "@mui/icons-material";
import { cardLookup } from "./helpers/cardLookup";
import cardBack from "./assets/cardBack.png";
import { sendFeedback } from "./service/FeedbackSlice";
import { getLocation } from "./service/LocationSlice";

const Home = () => {
    const view = [
        "clubs_ace", "clubs_2", "clubs_3", "clubs_4", "clubs_5", "clubs_6", "clubs_7", "clubs_8", "clubs_9", "clubs_10", "clubs_jack", "clubs_queen", "clubs_king",
        "spades_ace", "spades_2", "spades_3", "spades_4", "spades_5", "spades_6", "spades_7", "spades_8", "spades_9", "spades_10", "spades_jack", "spades_queen", "spades_king",
        "diamonds_ace", "diamonds_2", "diamonds_3", "diamonds_4", "diamonds_5", "diamonds_6", "diamonds_7", "diamonds_8", "diamonds_9", "diamonds_10", "diamonds_jack", "diamonds_queen", "diamonds_king",
        "hearts_ace", "hearts_2", "hearts_3", "hearts_4", "hearts_5", "hearts_6", "hearts_7", "hearts_8", "hearts_9", "hearts_10", "hearts_jack", "hearts_queen", "hearts_king"
    ]

    const dispatch = useDispatch()

    const createGameResponse = useSelector(gameCreationSelector)
    const joinGameResponse = useSelector(playerJoinSelector)
    const startGameResponse = useSelector(gameStartSelector)

    const [yourDeck, setYourDeck] = useState([])
    const [selectedCards, setSelectedCards] = useState([])
    const [selectValue, setSelectValue] = useState(null)
    const [open, setOpen] = useState(false)
    const [dialogDetails, setDialogDetails] = useState(null)
    const [players, setPlayers] = useState([])
    const [gameStats, setGameStats] = useState({})
    const [currentPlayer, setCurrentPlayer] = useState(null)
    const [check, setCheck] = useState(false)
    const [show, setShow] = useState(false)
    const [messageOpen, setMessageOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [showSuccess, setShowSuccess] = useState(false)
    const [alert, setAlert] = useState(false)

    useEffect(() => {
        if(sessionStorage.getItem("gameState")){
            if(sessionStorage.getItem("gameState") === "PLAY"){
                setOpen(false)
            }else{
                setOpen(true)
            }

            setDialogDetails(sessionStorage.getItem("gameState"))
        }else{
            setOpen(true)
            if(window.location.pathname === "/0"){
                setDialogDetails("CREATE")
            }else{
                setDialogDetails("JOIN")
            }
        }

        //es-lint-disable-next-line
    }, [dispatch])

    const handleCardClick = (name) => {
        if(selectedCards.length < 8){
            var index = yourDeck.findIndex(card => card.name === name)

            if(index.length !== -1){
                if(yourDeck[index].count === 1){
                    setYourDeck([
                        ...yourDeck.slice(0, index), 
                        ...yourDeck.slice(index + 1)
                    ])
                }else{
                    setYourDeck([
                        ...yourDeck.slice(0, index), 
                        {
                            "name": name,
                            "count": yourDeck[index]?.count - 1 
                        }, 
                        ...yourDeck.slice(index + 1)
                    ])
                }
                setSelectedCards([...selectedCards, name])
            }
        }
    }

    const handleCancelSelection = (name) => {
        var index = yourDeck.findIndex(card => card.name === name)
        var selectionIndex = selectedCards.findIndex(card => card === name)
        
        if(index !== -1){
            setYourDeck([
                ...yourDeck.slice(0, index), 
                {
                    "name": name,
                    "count": yourDeck[index]?.count + 1 
                }, 
                ...yourDeck.slice(index + 1)
            ])
        }else{
            setYourDeck([
                ...yourDeck,
                {
                    "name": name,
                    "count": 1
                }
            ])
        }

        setSelectedCards([...selectedCards.slice(0, selectionIndex), 
            ...selectedCards.slice(selectionIndex + 1)])
    }

    const handleReset = () => {
        dispatch(getGame(window.location.pathname.split('/')[1], (response) => {
            var player = response.players.find(player => player.id === sessionStorage.getItem("playerId"))
            setYourDeck(player.cards)
            setSelectedCards([])
        }))
        setSelectValue(null)
    }

    const handlePlay = () => {
        dispatch(playCards(window.location.pathname.split('/')[1], 
            sessionStorage.getItem("playerId"), 
            {
                cards: yourDeck,
                selectedCards: selectedCards,
                round: selectValue
            }, 
            (response) => {
                dispatch(getGame(window.location.pathname.split('/')[1], (response) => {
                    var player = response.players.find(player => player.id === sessionStorage.getItem("playerId"))
                    setYourDeck(player.cards)
                    setSelectedCards([])
                }))               
            }
        ))
    }

    const handlePass = () => {
        setSelectedCards([])
        dispatch(passRound(window.location.pathname.split('/')[1], sessionStorage.getItem("playerId")), (response) => {
            console.log(response)
        })
    }

    const handleCheck = () => {
        setCheck(true)
        dispatch(checkCards(window.location.pathname.split('/')[1], sessionStorage.getItem("playerId"), 
            {selectedCards : gameStats.lastPlayedCards}, (response) => {
                dispatch(getGame(window.location.pathname.split('/')[1], (response) => {
                    var player = response.players.find(player => player.id === sessionStorage.getItem("playerId"))
                    setYourDeck(player.cards)
                    setSelectedCards([])
                    setCheck(false)
                }))
            },
            (error) => {
                console.log("There was an error while performing check action", error)
                setCheck(false)
            }
        ))
    }

    const handleClear = () => {
        dispatch(clearCheckedBy(window.location.pathname.split('/')[1], (response) => {
            console.log(response)
        }))
    
    }

    const handleMessageChange = (event) => {
        setShowSuccess(false)
        setMessage(event.target.value)
    }

    const handleFeedbackClick = () => {
        dispatch(
            sendFeedback({
                playerId: sessionStorage.getItem("playerId"),
                message: message
            }, (response) => {
                setMessage("")
                setShowSuccess(true)
            })
        )
    }

    useEffect(() => {
        if(selectedCards.length !== 0){
            if(gameStats && gameStats.round !== null && gameStats.round !== undefined){
                setSelectValue(gameStats.round)
            }else{
                setSelectValue(selectedCards[selectedCards.length - 1].split('_')[1])
            }
        }else{
            setSelectValue(null)
        }
        //eslint-disable-next-line
    },[selectedCards])

    useEffect(() => {
        if(createGameResponse){
            sessionStorage.setItem("gameState", "JOIN")
            setDialogDetails("JOIN")
            window.history.pushState("", "", createGameResponse)
        }
        //eslint-disable-next-line
    }, [createGameResponse])

    useEffect(() => {
        if(joinGameResponse){
            sessionStorage.setItem("gameState", "START")
            sessionStorage.setItem("playerId", joinGameResponse)
            setDialogDetails("START")

            dispatch(getLocation(window.location.pathname.split('/')[1], joinGameResponse))

            var checkGameStarted = setInterval(() => {
                dispatch(getGame(window.location.pathname.split('/')[1], (response) => {
                    if(response.state === "STARTED"){
                        setOpen(false)
                        sessionStorage.setItem("gameState", "PLAY")
                        setDialogDetails("PLAY")
                        setOpen(false)
                        clearInterval(checkGameStarted)
                    }
                }))
            }, 1000)
        }
        //eslint-disable-next-line
    },[joinGameResponse])

    useEffect(() => {
        if(startGameResponse.message){
            sessionStorage.setItem("gameState", "PLAY")
            setDialogDetails("PLAY")
            setOpen(false)

            dispatch(getGame(window.location.pathname.split('/')[1], (response) => {
                setPlayers(response.players)

                var player = response.players.find(player => player.id === sessionStorage.getItem("playerId"))
                setYourDeck(player.cards)
            }))
        }
        //eslint-disable-next-line
    }, [startGameResponse])

    useEffect(() => {
        if(open === false && sessionStorage.getItem("gameState") === "PLAY"){
            dispatch(getGame(window.location.pathname.split('/')[1], (response) => {
                setPlayers(response.players)

                var player = response.players.find(player => player.id === sessionStorage.getItem("playerId"))
                setYourDeck(player.cards)
            }))
            var fetchGame = setInterval(() => {
                dispatch(getGame(window.location.pathname.split('/')[1], (response) => {
                    setPlayers(response.players)
                    var lastPlayer = response.players.find(player => player.id === response.lastPlayer)
                    setGameStats({
                        round: response.round,
                        checkedBy: response.checkedBy,
                        totalCards: response.board.length,
                        lastPlayer: lastPlayer?.name,
                        lastPlayerId: response.lastPlayer,
                        lastPlayedCards: lastPlayer?.selectedCards,
                        gameState: response.state
                    })

                    if(response.checkedBy !== null && response.checkedBy !== undefined){
                        setTimeout(() => {
                            handleClear()
                        },[10000])
                    }

                    if(response.round !== null && response.round !== undefined){
                        setSelectValue(response.round)
                    }

                    setCurrentPlayer(response.currentPlayer)
                }))
            }, 1000)
        }

        if(open === true && sessionStorage.getItem("gameState") === "COMPLETED"){
            clearInterval(fetchGame)
        }
        //eslint-disable-next-line
    }, [open])

    useEffect(() => {
        var player = players.find(player => player.id === sessionStorage.getItem("playerId"))
        if(player?.cards?.length && player?.cards?.length !== (yourDeck?.length + selectedCards?.length)){
            setYourDeck(player?.cards)
            setSelectedCards([])
        }
        //eslint-disable-next-line
    },[players])

    useEffect(() => {
        if(gameStats.gameState === "COMPLETED"){
            sessionStorage.setItem("gameState", "COMPLETED")
            setDialogDetails("COMPLETED")
            setOpen(true)
        }
        //eslint-disable-next-line
    },[gameStats.gameState])

    useEffect(() => {
        setShow(false)
        setTimeout(() => {
            setShow(true)
        }, 500)
    },[currentPlayer])

    useEffect(() => {
        if(window.innerWidth < 900){
            setAlert(true)

            setTimeout(() => {
                setAlert(false)
            }, 2000)
        }
        //eslint-disable-next-line
    },[window.innerWidth])
    
    return(
        <Grid container style={{
            background: `radial-gradient(${colors.green[400]}, ${colors.green[900]})`,
            position: "relative",
            minHeight: "100vh",
            overflowX: "hidden"
        }}>
            <Grid item xs={12} sm={12} md={10} lg={10} style={{padding: "20px"}}>
                <Box>
                    <Grid container>
                        <Grid item xs={2} sm={2} md={2} lg={2} style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                            <Typography variant="body1" style={{color: colors.grey["A100"]}}>Card in play</Typography>
                            <Box style={{
                                width: "calc(38px + 3vw)",
                                height: "calc(60px + 3vw)",
                                backgroundColor: colors.grey[50],
                                borderRadius: "5px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "6px"
                            }}>
                                <Typography variant="h6" style={{
                                    textAlign: "center", 
                                    color: colors.green[900],
                                    fontWeight: "bold",
                                }}>
                                    {gameStats?.round}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8}>
                            <Box style={{display: 'flex', alignItems: "center", flexDirection: "column"}}>
                                {gameStats.checkedBy ?
                                    <Box style={{color: colors.grey[50]}}>
                                        <Typography variant="h6" style={{textAlign: "center"}}>Cards were checked By</Typography>
                                        <Typography variant="h5" style={{textAlign: "center"}}>
                                            {players.filter(player => player.id === gameStats.checkedBy)[0].name}
                                        </Typography>
                                    </Box> : 
                                    <Typography variant="body1" style={{
                                        color: colors.grey["A100"]
                                    }}>
                                        Last Played Cards, by player: {gameStats.lastPlayer}
                                    </Typography>
                                }
                            </Box>
                            <Box style={{display: "flex", justifyContent: "space-between"}}>
                                <Box style={{display: "flex", position: "relative"}}>
                                    {gameStats.lastPlayedCards && gameStats.lastPlayedCards.length !== 0 && 
                                        gameStats.lastPlayedCards.map((card, index) => (
                                            <Box style={{
                                                position: "absolute", 
                                                top: 22, 
                                                left: show ? `calc(${parseInt(index) * 40}px)` : 0, 
                                                width: "calc(38px + 3vw)",
                                                height: "calc(60px + 3vw)",
                                                transition: "all 0.5s",
                                            }} key={index}>
                                                <img src={check ? cardLookup(card) : cardBack} style={{
                                                    display: "block",
                                                    maxWidth: "100%",
                                                    height: "100%",
                                                    transform:  "rotate(90deg)",
                                                    objectFit: "contain",
                                                }} alt="deck-card"/>
                                            </Box>
                                        ))
                                    }
                                </Box>
                                <Box>
                                    {gameStats.lastPlayedCards && gameStats.lastPlayedCards.length !== 0 &&
                                        <Button 
                                            variant="contained" 
                                            color="primary" 
                                            style={{margin: "10px"}}
                                            disabled = {
                                                sessionStorage.getItem("playerId") === gameStats.lastPlayerId ||
                                                gameStats.gameState === "COMPLETED" ||
                                                players.filter(player => 
                                                    player.id === sessionStorage.getItem("playerId")
                                                )[0].playing === "WON"
                                            }
                                            onClick={handleCheck}
                                        >
                                            Check
                                        </Button>
                                    }
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2} style={{display: 'flex', alignItems: "center", flexDirection: "column"}}>
                            <Typography variant="body1" style={{color: colors.grey["A100"]}}>Total cards played: {gameStats.totalCards} </Typography>
                            <Box style={{position: 'relative', marginTop: "-25px"}}>
                                {Array.from({length: 5}, (_, i) => i).map((card, index) => (
                                    <Box style={{
                                        position: "absolute",
                                        width: "calc(48px + 3vw)",
                                        height: "calc(70px + 3vw)",
                                        zIndex: index,
                                        top: show ? 20 : 0,
                                        left: `calc(${4 * index}px - 50px)`,
                                        transition: "all 0.5s",
                                    }}>
                                        <img src={cardBack} style={{
                                            display: "block",
                                            maxWidth: "100%",
                                            height: "100%",
                                            transform:  "rotate(90deg)",
                                            objectFit: "contain",
                                        }} alt="deck-card"/>
                                    </Box>
                                ))}
                            </Box>
                        </Grid>
                    </Grid>
                </Box> 
                <Box style={{
                    display: "flex", 
                    flexWrap: "wrap", 
                    justifyContent: "center", 
                    alignItems: "center",
                    padding: "20px 0",
                    borderRadius: "5px",
                    marginBottom: "20px",
                    minHeight: "70px",
                    marginTop: "20px"
                }}>
                    {selectedCards.map((card, index) => (
                        <CardSelection key={index} image={card} onClick={handleCancelSelection}/>
                    ))}
                </Box>
                <Box style={{
                    display: "flex", 
                    flexWrap: "wrap", 
                    justifyContent: "center", 
                    alignItems: "center",
                    padding: "20px 0",
                    borderRadius: "10px"
                }}>
                    {view.map((card, index) => (
                        <GameCard 
                            image={card}
                            key={index} 
                            gameCards={yourDeck}
                            selectedCards={selectedCards}
                            onClick={handleCardClick}
                        />
                    ))}
                </Box>
                <Box style={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}>
                    <Button variant="contained" color="warning" style={{margin: "10px"}} onClick={handleReset}>Reset</Button>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        style={{margin: "10px"}}
                        disabled = {
                            sessionStorage.getItem("playerId") !== currentPlayer ||
                            gameStats.gameState === "COMPLETED" ||
                            gameStats.round === null || gameStats.round === undefined
                        }
                        onClick={handlePass}
                    >
                        Pass
                    </Button>
                        <Select variant="outlined" disabled={gameStats.round} 
                            value={selectValue} 
                            size="small" 
                            style={{
                                margin: "10px",
                                width: "18%",
                            }}
                            onChange={(e) => setSelectValue(e.target.value)}
                        >
                            <MenuItem value="ace">Callout {selectedCards.length} of Ace</MenuItem>
                            <MenuItem value="king">Callout {selectedCards.length} of King</MenuItem>
                            <MenuItem value="queen">Callout {selectedCards.length} of Queen</MenuItem>
                            <MenuItem value="jack">Callout {selectedCards.length} of Jack</MenuItem>
                            <MenuItem value="10">Callout {selectedCards.length} of 10</MenuItem>
                            <MenuItem value="9">Callout {selectedCards.length} of 9</MenuItem>
                            <MenuItem value="8">Callout {selectedCards.length} of 8</MenuItem>
                            <MenuItem value="7">Callout {selectedCards.length} of 7</MenuItem>
                            <MenuItem value="6">Callout {selectedCards.length} of 6</MenuItem>
                            <MenuItem value="5">Callout {selectedCards.length} of 5</MenuItem>
                            <MenuItem value="4">Callout {selectedCards.length} of 4</MenuItem>
                            <MenuItem value="3">Callout {selectedCards.length} of 3</MenuItem>
                            <MenuItem value="2">Callout {selectedCards.length} of 2</MenuItem>
                        </Select>
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        style={{margin: "10px"}} 
                        onClick={handlePlay}
                        disabled = {
                            sessionStorage.getItem("playerId") !== currentPlayer ||
                            selectedCards.length === 0 ||
                            gameStats.gameState === "COMPLETED"
                        }
                    >
                        Play
                    </Button>
                </Box>                
            </Grid> 
            <Grid item xs={12} sm={12} md={2} lg={2}>
                <Box sx={{
                    height: {md: "100vh", lg: "100vh", sm: "100%", xs: "100%"},
                    borderLeft: `2px solid ${colors.green[900]}`,
                }}>
                    <List>
                        {players.map(player => (
                            <ListItem key={player.id} style={{
                                background: 
                                    (player.id === currentPlayer && colors.green[200]) ||
                                    (player.playing === "PASS" && colors.red[100]),
                                color: 
                                    (player.playing === "PASS" && colors.red[900]) ||
                                    (player.id === currentPlayer && colors.green[900]) ||
                                    (colors.grey[50]),
                            }}>
                                <Box style={{display: "flex", alignItems: "center"}}>
                                    <Person2Rounded style={{fontSize: "20px"}}/>
                                    <Box style={{
                                        paddingLeft: "20px", 
                                        display: "flex", 
                                        flexDirection: "column", 
                                        alignItems: "flex-start"
                                    }}>
                                        <Typography variant="body2">{player.name}</Typography>
                                        <Typography variant="caption" style={{
                                            color: colors.green[900],
                                            fontWeight: "bold"
                                        }}>
                                            {player.id === currentPlayer && "Current Player"}
                                        </Typography>
                                        <Typography variant="caption">Status: {player.playing || "PLAY"}</Typography>
                                    </Box>
                                </Box>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Grid>
            <Dialog open={open} disableEscapeKeyDown PaperProps={{
                sx: {
                    width: {lg: "50%", md: "50%", sm: "100%", xs: "100%"},
                }
            }}>
                {(() => {
                    switch(dialogDetails){
                        case "CREATE":
                            return (<NewGame />)
                        case "JOIN":
                            return (<JoinGame game={window.location.pathname.split('/')[1]}/>)
                        case "START":
                            return (<StartGame game={window.location.pathname.split('/')[1]}/>)
                        case "COMPLETED":
                            return (
                                <Box style={{padding: "2vw"}}>
                                    <Typography variant="h4" style={{textAlign: "center", marginBottom: "2vw"}}>
                                        Game Completed
                                    </Typography>
                                    <Typography variant="h6">
                                        Players Board
                                        <List>
                                            {players.map(player => (
                                                <ListItem key={player.id} style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                                                    <Typography variant="body1">{player.name}</Typography>
                                                    <Typography variant="body2">Status: {player.playing}</Typography>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Typography>
                                    <Button variant="contained" color="primary" onClick={() => {
                                        setDialogDetails("CREATE")
                                        window.location.pathname = "/"
                                        sessionStorage.setItem("gameState", "CREATE")
                                    }}>New Game</Button>
                                </Box>
                            )
                        default:
                            return (<NewGame />)
                    }
                })()}
            </Dialog>
            <Box style={{
                position: "absolute",
                width: "100%",
                top: "auto",
                bottom: "10px",
                left: "10px"
            }}>
                <Fab color="default" onClick={() => setMessageOpen(true)}>
                    <Message />
                </Fab>
            </Box>
            <Dialog open={messageOpen} onClose={() => {
                setMessageOpen(false)
                setShowSuccess(false)
            }} PaperProps={{
                sx: {
                    width: {lg: "50%", md: "50%", sm: "100%", xs: "100%"},
                    padding: "20px"
                }
            }}>
                {showSuccess && <Alert icon={<TaskAlt fontSize="inherit" />} severity="success">
                    Your feedback has been submitted successfully
                </Alert>}
                <Typography variant="h5" style={{textAlign: "center", padding: "20px"}}>
                    Please enter your feedback about the game, if any
                </Typography>
                <TextField variant="outlined" 
                    label="Message" 
                    multiline 
                    rows={4} 
                    style={{width: "100%"}}
                    value={message}
                    onChange={handleMessageChange}
                />
                <Box style={{display: "flex", justifyContent: "center"}}>
                    <Button variant="contained" 
                        color="primary" 
                        style={{marginTop: "20px", width: "20%"}}
                        onClick={handleFeedbackClick}
                    >
                        Submit
                    </Button>
                </Box>
            </Dialog>
            <Dialog open={alert} onClose={() => setAlert(false)} 
            PaperProps={{
                sx: {
                    width: {lg: "50%", md: "50%", sm: "100%", xs: "100%"},
                    padding: "20px"
                }
            }}>
                <Typography variant="h5" style={{color: colors.amber[900], textAlign: "center", marginBottom: "10px"}}>Warning!</Typography>
                <Typography variant="body1" style={{textAlign: "center"}}>For better experience, please use a device with a larger screen size.</Typography>
            </Dialog>
        </Grid>
    )
}

export default Home;