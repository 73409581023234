// export const backendUrl = "http://localhost:3001/api/";
export const backendUrl = "https://api.play-bluff.com/api/";

export const HttpRequest = (
    method,
    url,
    body,
    successHandler,
    errorHandler,
) => {
        let requestParams = {
            method,
            headers: { 
                "Content-Type": "application/json",
            }
        }

        if(Boolean(body)){
            requestParams = {...requestParams, body: JSON.stringify(body)}
        }

        fetch(`${backendUrl}${url}`, 
            requestParams
        ).then(result => {
            result.json().then(jsonResponse => {
                if(result.ok){
                    if(typeof successHandler === "function"){
                        successHandler(jsonResponse)
                    }else{
                        console.log("Success response", jsonResponse)
                    }
                }else{
                    if(typeof errorHandler === "function"){
                        errorHandler(jsonResponse)
                    }
                    console.log("Error in response", jsonResponse) 
                }
            }).catch(error => {
               console.log("Error from backend", error)
            })
        }).catch(error => {
            if(typeof errorHandler === "function"){
                errorHandler(error)
            }
            console.log("Error from backend", error)
        })
}
