import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import Instructions from "./Instructions";

const App = () => {
  return (
    <Routes>
      <Route path="/:id" element={<Home />} />
      <Route path="/" element={<Instructions />} />
    </Routes>
  )
}

export default App;
