import { Box, Button, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createGame } from "./service/GameInitSlice";

const NewGame = () => {
    const dispatch = useDispatch()

    const [gameEntryForm, setGameEntryForm] = useState({})
    const [deckCountOpts, setDeckCountOpts] = useState([1])

    const handleChange = (event) => {
        setGameEntryForm({
            ...gameEntryForm,
            [event.target.name]: event.target.value
        })
    }

    const handleSubmit = () => {
        if(gameEntryForm.deckCount){
            dispatch(createGame(gameEntryForm))
        }else{
            dispatch(createGame({...gameEntryForm, deckCount: 1}))
        }
    }

    useEffect(() => {
        if(gameEntryForm.playersCount && gameEntryForm.playersCount.match(/^[(2-8)]$/g)){
            if(gameEntryForm.playersCount >= 2 && gameEntryForm.playersCount < 4){
                setDeckCountOpts([1])
            }else{
                var minDeckCount = Math.floor(gameEntryForm.playersCount / 2)
                var maxDeckCount = Math.floor(gameEntryForm.playersCount)

                setDeckCountOpts([...Array(maxDeckCount - minDeckCount + 1).keys()].map(i => i + minDeckCount))
            }
        }
    }, [gameEntryForm.playersCount])

    return(
        <Box style={{
            padding: "2vw"
        }}>
            <Box style={{marginBottom: "3vw", textAlign: "center"}}>
                <Typography variant="h4" style={{textAlign: "center"}}>
                    Start a New Game
                </Typography>
                <Typography variant="body2">
                    Enter the details of the game you want to start
                </Typography>
            </Box>
            <Grid container spacing={2} style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField 
                        value={gameEntryForm.room}
                        variant="outlined"
                        label="Room Name"
                        type="text"
                        name="room"
                        required
                        error={gameEntryForm.room && gameEntryForm.room !== "" ? false : true}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                    <TextField
                        value={gameEntryForm.playersCount}
                        variant="outlined"
                        label="Players#"
                        type="number"
                        error={gameEntryForm.room && 
                            gameEntryForm.room !== "" && 
                            (gameEntryForm.playersCount)?.match(/^[(2-8)]$/g) ? false 
                        : true}
                        name="playersCount"
                        required
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                    <TextField
                        value={gameEntryForm.deckCount || 1}
                        variant="outlined"
                        label="Decks#"
                        labelId="deckCount"
                        name="deckCount"
                        required
                        select
                        onChange={handleChange}
                        fullWidth
                    >
                        {deckCountOpts.map((opt, i) => (
                            <MenuItem key={i} value={opt}>{opt}</MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Button 
                    variant="contained" 
                    color="primary" 
                    style={{marginTop: "1vw"}}
                    onClick={handleSubmit}
                    disabled={
                        gameEntryForm.room &&
                        gameEntryForm.room !== "" && 
                        gameEntryForm.playersCount && 
                        gameEntryForm.playersCount !== "" &&
                        gameEntryForm.playersCount.match(/^[(2-8)]$/g) ? false 
                    : true}
                >
                    Submit
                </Button>
            </Grid>
        </Box>
    )
}

export default NewGame;