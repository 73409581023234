import { createSlice } from "@reduxjs/toolkit";
import { HttpRequest } from "../context/ApiRequest";

const playerActionSlice = createSlice({
    name: "playerAction",
    initialState: {},
    reducers: {}
});

export const playCards = (gameId, playerId, boardDetails, completionHandler) => {
    return(dispatch) => {
        HttpRequest("PUT", `player/play/${gameId}/${playerId}`, boardDetails, completionHandler)
    }
}

export const passRound = (gameId, playerId, completionHandler) => {
    return(dispatch) => {
        HttpRequest("PUT", `player/pass/${gameId}/${playerId}`, null, completionHandler)
    }
}

export const checkCards = (gameId, playerId, selectedCards, completionHandler, errorHandler) => {
    return(dispatch) => {
        HttpRequest("PUT", `player/check/${gameId}/${playerId}`, selectedCards, completionHandler, errorHandler)
    }
}

export default playerActionSlice.reducer;