import { createSlice } from "@reduxjs/toolkit";
import { HttpRequest } from "../context/ApiRequest";

const feedbackSlice = createSlice({
    name: "feedback",
    initialState: {},
    reducers: {}
});

export const sendFeedback = (feedbackDetails, completionHandler) => {
    return(dispatch) => {
        HttpRequest("POST", "feedback/create", feedbackDetails, completionHandler)
    }
}

export default feedbackSlice.reducer;