import { Box, Button, Typography, colors } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Instructions = () => {
    const navigate = useNavigate();

    return(
        <Box container style={{
            background: `radial-gradient(${colors.green[400]}, ${colors.green[900]})`,
            position: "relative",
            padding: "2vw 5vw",
            color: colors.common.white,
            minHeight: "calc(100vh - 4vw)",
        }}>
            <Typography variant="h1" style={{fontFamily: "cursive", textAlign: "center", marginBottom: "40px"}}>Bluff</Typography>
            <Box style={{marginBottom: "50px"}}>
                <Typography variant="h5" style={{fontFamily: "fantasy", marginBottom: "15px"}}>Overview</Typography>
                <Typography variant="body1">Bluff, also known as Cheat or I Doubt It, is a classic card game where players aim to get rid of all their cards by bluffing about the cards they play. Starting with one player placing cards face down and declaring their rank, subsequent players must either play cards of the same rank or bluff by playing different cards while claiming they are the same rank. If a bluff is called successfully, the bluffer takes the pile; if not, the caller takes it. The first player to empty their hand wins. Bluff requires a mix of strategy, memory, and the ability to deceive opponents.</Typography>
            </Box>
            <Box>
                <Typography variant="h5" style={{fontFamily: "fantasy"}}>Refer the instructions below to play bluff</Typography>
                <ol>
                    <li>
                        <Typography variant="body1">
                        <strong>Number of Players:</strong> 2-8 players of any age group can play.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                        <strong>Deck Selection:</strong> Players choose the number of card decks they want to play with.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                        <strong>Card Distribution:</strong> Cards from all decks are equally distributed to each player. No Joker cards are involved.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                        <strong>Card Families:</strong> Cards are grouped into families of 13, consisting of Ace, 1-10, Jack, Queen, and King, with the same number but different shapes and colors.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                        <strong>Player Turn:</strong> On their turn, a player can select up to 8 cards and call out the family of the cards.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                        <strong>Bluffing:</strong> Other players can check the called-out cards to determine if they belong to the called family (not a bluff) or not.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                        <strong>Outcome of Check:</strong> If it's not a bluff, the checking player receives all the cards on the table. If it is a bluff, the player who called out the family receives the cards.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                        <strong>Round End:</strong> The round ends when all players pass or when a bluff is detected. All cards on the table are discarded, and a new round begins.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                        <strong>Winning:</strong> The player who empties all their given cards first wins. The game ends when all players but one have won.
                        </Typography>
                    </li>
                </ol>
            </Box>
            <Box style={{display: 'flex', justifyContent: 'center', marginTop: "50px"}}>
                <Button variant="contained" style={{
                    background: colors.common.white,
                    color: colors.green[900],
                }} onClick={() => navigate("/0")}>Start Game</Button>
            </Box>
        </Box>
    )
}

export default Instructions;