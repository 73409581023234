import { createSlice } from "@reduxjs/toolkit";
import { HttpRequest } from "../context/ApiRequest";

const locationSlice = createSlice({
    name: "location",
    initialState: {},
    reducers: {}
})

export const getLocation = (gameId, playerId, completionHandler) => {
    return(dispatch) => {
        fetch('https://api-bdc.net/data/ip-geolocation?key=bdc_8ecf50a6a22c41feb798edcb472a40ff', 
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                }
            }
        ).then(result => {
            result.json().then(jsonResponse => {
                if(result.ok){
                    dispatch(HttpRequest("POST", `session/create`, {
                        game: gameId,
                        player: playerId,
                        requestIp: jsonResponse.ip,
                        country: jsonResponse.country.name,
                        countryCode: jsonResponse.country.isoAlpha2,
                        state: jsonResponse.location.principalSubdivision,
                        city: jsonResponse.location.city,
                        latitude: jsonResponse.latitude,
                        longitude: jsonResponse.longitude
                    }, (response) => {
                        completionHandler && completionHandler(response)
                    }))
                }else{
                    console.log("Error in response", jsonResponse) 
                }
            }).catch(error => {
               console.log("Error from backend", error)
            })
        }).catch(error => {
            console.log("Error from backend", error)
        })
    }
}

export default locationSlice.reducer;