import { combineReducers } from "@reduxjs/toolkit";
import gameInitReducer from "../service/GameInitSlice";
import playerActionReducer from "../service/PlayerActionSlice";
import locationReducer from "../service/LocationSlice";

const appReducer = combineReducers({
    gameInit: gameInitReducer,
    player: playerActionReducer,
    location: locationReducer
});

export const rootReducer = (state, action) => {
    return appReducer(state, action);
}

