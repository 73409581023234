import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { joinGame } from "./service/GameInitSlice";
import { useDispatch } from "react-redux";
import { useState } from "react";

const JoinGame = (props) => {
    const { game } = props

    const dispatch = useDispatch()

    const [joinForm, setJoinForm] = useState({})

    const handleChange = (event) => {
        setJoinForm({
            ...joinForm,
            [event.target.name]: event.target.value
        })
    }

    const handleSubmit = () => {
        dispatch(joinGame(game, joinForm))
    }

    return (
        <Box style={{
            padding: "2vw"
        }}>
            <Box style={{marginBottom: "3vw", textAlign: "center"}}>
                <Typography variant="h4" style={{textAlign: "center"}}>
                    Join a Game
                </Typography>
                <Typography variant="body2">
                    Enter your name and join the game
                </Typography>
            </Box>
            <Grid container style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                        value={joinForm.name}
                        variant="outlined"
                        label="Name"
                        type="text"
                        name="name"
                        required
                        error={!joinForm.name || joinForm.name === ""}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Button 
                    variant="contained"
                    color="primary" 
                    style={{marginTop: "1vw"}}
                    onClick={handleSubmit}
                    required
                    disabled={!joinForm.name || joinForm.name === ""}
                >
                    Join
                </Button>
            </Grid>
        </Box>
    )
}

export default JoinGame;